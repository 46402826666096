import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/Users/apple/Documents/h-fish/dashboard/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/apple/Documents/h-fish/dashboard/node_modules/core-js/modules/es6.promise.js";
import "/Users/apple/Documents/h-fish/dashboard/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/apple/Documents/h-fish/dashboard/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n
// import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import VueQuillEditor from 'vue-quill-editor';
import { editorOptions } from '@/utils/quill-edit';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as filters from "./filters";
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon

import '@/styles/index.scss'; // global css

import '@/permission'; // permission control

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
// require styles

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuillEditor, editorOptions); // Vue.use(VueI18n)
// Vue.use(Element)
// Vue.config.lang = 'zh-cn'
// Vue.locale('zh-cn', zhLocale)
// Vue.locale('en', enLocale)

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

import { mockXHR } from "../mock";

if (process.env.NODE_ENV === 'production') {
  mockXHR();
} // set ElementUI lang to EN


Vue.use(ElementUI, {
  locale: locale
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});