import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/news/index');
    },
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    }
  }]
}, {
  path: '/news',
  component: Layout,
  name: 'News',
  meta: {
    title: '资讯管理',
    icon: 'example'
  },
  children: [{
    path: 'news',
    name: 'news',
    component: function component() {
      return import('@/views/news/index');
    },
    meta: {
      title: '资讯列表',
      icon: 'news'
    }
  }]
}, {
  path: '/exhibition',
  component: Layout,
  name: 'Exhibition',
  meta: {
    title: '展会管理',
    icon: 'example'
  },
  children: [{
    path: 'exhibition',
    name: 'exhibition',
    component: function component() {
      return import('@/views/exhibition/index');
    },
    meta: {
      title: '展会列表',
      icon: 'nested'
    }
  }, {
    path: 'category',
    name: 'category',
    component: function component() {
      return import('@/views/category/index');
    },
    meta: {
      title: '展会分类',
      icon: 'category'
    }
  }, {
    path: 'exhibition/qrcode',
    name: 'exhibitionQrcode',
    hidden: true,
    component: function component() {
      return import('@/views/exhibition/list');
    },
    meta: {
      title: '展会订单',
      icon: 'order'
    }
  }]
}, {
  path: '/hall',
  component: Layout,
  name: 'Hall',
  meta: {
    title: '展厅管理',
    icon: 'example'
  },
  children: [{
    path: 'hall',
    name: 'hall',
    component: function component() {
      return import('@/views/hall/index');
    },
    meta: {
      title: '展厅列表',
      icon: 'hall'
    }
  }, {
    path: 'hall/edit',
    name: 'hallEdit',
    hidden: true,
    component: function component() {
      return import('@/views/hall/edit');
    },
    meta: {
      title: '编辑展厅',
      icon: 'table'
    }
  }]
}, {
  path: '/vip',
  component: Layout,
  name: 'vip',
  meta: {
    title: '套票管理',
    icon: 'example'
  },
  children: [{
    path: 'vip',
    name: 'vip',
    component: function component() {
      return import('@/views/ticket/index');
    },
    meta: {
      title: '套票管理',
      icon: 'vip'
    }
  }]
}, {
  path: '/subscribe',
  component: Layout,
  name: 'Subscribe',
  meta: {
    title: '预约管理',
    icon: 'form'
  },
  children: [{
    path: 'subscribe',
    name: 'subscribe',
    component: function component() {
      return import('@/views/subscribe/index');
    },
    meta: {
      title: '预约列表',
      icon: 'nested'
    }
  }, {
    path: 'from',
    name: 'From',
    component: function component() {
      return import('@/views/from/index');
    },
    meta: {
      title: '预约申请',
      icon: 'form'
    }
  }]
}, {
  path: '/company',
  component: Layout,
  redirect: '/company',
  children: [{
    path: 'company',
    name: 'Company',
    component: function component() {
      return import('@/views/company/index');
    },
    meta: {
      title: '企业管理',
      icon: 'company'
    }
  }]
}, {
  path: '/banner',
  component: Layout,
  redirect: '/banner',
  children: [{
    path: 'banner',
    name: 'Banner',
    component: function component() {
      return import('@/views/banner/index');
    },
    meta: {
      title: 'Banner 管理',
      icon: 'banner'
    }
  }]
}, {
  path: '/notice',
  component: Layout,
  redirect: '/notice',
  children: [{
    path: 'notice',
    name: 'Notice',
    component: function component() {
      return import('@/views/notive/index');
    },
    meta: {
      title: '公告管理',
      icon: 'notice'
    }
  }]
}, {
  path: '/user',
  component: Layout,
  redirect: '/user',
  children: [{
    path: 'user',
    name: 'User',
    component: function component() {
      return import('@/views/user/index');
    },
    meta: {
      title: '用户',
      icon: 'user'
    }
  }]
}, {
  path: '/admin',
  component: Layout,
  redirect: '/admin',
  children: [{
    path: 'admin',
    name: 'Admin',
    component: function component() {
      return import('@/views/admin/index');
    },
    meta: {
      title: '管理员',
      icon: 'admin'
    }
  }]
}, // {
//   path: '/form',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       name: 'Form',
//       component: () => import('@/views/form/index'),
//       meta: { title: 'Form', icon: 'form' }
//     }
//   ]
// },
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;