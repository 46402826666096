import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import moment from 'moment';
export function money(str) {
  if (typeof str === 'number') str = str.toString();
  var re = /\d{1,3}(?=(\d{3})+$)/g;
  return "\xA5 ".concat(str.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, "$&,") + s2;
  }));
}
export function genderMap(gender) {
  switch (gender) {
    case 0:
      return '未知';

    case 1:
      return '男性';

    case 2:
      return '女性';
  }
}
export function languageMap(language) {
  switch (language) {
    case 'en':
      return '英文';

    case 'zh_CN':
      return '简体中文';

    case 'zh_TW':
      return '繁体中文';
  }
}
export function dateFormat(time) {
  return moment(time).format('YYYY-MM-DD');
}
export function dateFormats(time) {
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}
export function dateFormatMIN(time) {
  return moment(time).format('YYYY-MM-DD HH:mm');
}
export function dateFormatTime(time) {
  return moment(time).format('HH:mm');
}
export function dateFormatmin(time) {
  if (time === null || time === undefined) return '';
  return moment(time).format('HH:mm:ss');
}
export function makeTime(day, time) {
  if (day !== null && time !== null) {
    var newtime = moment(day).format('YYYY-MM-DD') + '' + time;
    return moment(newtime, 'YYYY-MM-DD HH:mm').valueOf();
  } else {
    return undefined;
  }
}