import { login as _login } from '@/api/admin';
import { getToken, setToken, removeToken, getUser, setUser, removeUser } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  user: getUser()
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER: function SET_USER(state, user) {
    state.user = user;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        if (response.status) {
          var token = response.token,
              admin = response.admin;
          commit('SET_TOKEN', token);
          commit('SET_USER', admin);
          setUser(JSON.stringify(admin));
          setToken(token);
          resolve();
        } else reject('邮箱或密码错误！');
      }).catch(function (error) {
        console.log('error', error);
        reject('提交出错');
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {// return new Promise((resolve, reject) => {
    //   getInfo(state.token).then(response => {
    //     const { data } = response
    //     if (!data) {
    //       reject('Verification failed, please Login again.')
    //     }
    //     const { name, avatar } = data
    //     commit('SET_NAME', name)
    //     commit('SET_AVATAR', avatar)
    //     resolve(data)
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })

    var commit = _ref2.commit,
        state = _ref2.state;
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeUser();
      removeToken();
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeUser();
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};