import request from '@/utils/request';
var name = "admin";
export function getList() {
  return request({
    url: "/".concat(name),
    method: 'get'
  });
}
export function get(_id) {
  return request({
    url: "/".concat(name, "/select/").concat(_id),
    method: 'get'
  });
}
export function register(data) {
  return request({
    url: "/".concat(name, "/register"),
    method: 'post',
    data: data
  });
}
export function login(data) {
  console.log('data', data);
  return request({
    url: "/".concat(name, "/login"),
    method: 'post',
    data: data
  });
}
export function update(data) {
  return request({
    url: "/".concat(name),
    method: 'put',
    data: data
  });
}
export function del(params) {
  return request({
    url: "/".concat(name),
    method: 'delete',
    params: params
  });
}
export function getSetting() {
  return request({
    url: "/setting/one",
    method: 'get'
  });
}
export function postSetting(data) {
  return request({
    url: "/setting",
    method: 'post',
    data: data
  });
}
export function updateSetting(data) {
  return request({
    url: "/setting",
    method: 'put',
    data: data
  });
}